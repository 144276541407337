.buttonPrimary {
  color: #fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  display: block;
  width: 100%;
  background-color: #0053a6 !important;
  text-align: center;
}

.buttonPrimary:hover {
  color: #fff;
  background-color: #036 !important;
}

.accordionButton {
  color: white !important;
  background-color: #0066cc !important;
  font-size: x-large;
}

.accordionButton:hover {
  background-color: #0053a6 !important;
}

.accordionButton:active {
  background-color: #004d99 !important;
}

.marginTopBottom {
  margin-top: 2%;
  margin-bottom: 2%;
}

.collapse-header [data-toggle="collapse"]:hover {
  text-decoration: none;
}

.removeZIndex .calendar-input-container {
  z-index: 0;
}

.clickableLabel > label {
  pointer-events: none;
}

.dropdown-style {
  padding: 0;
}

.dropdown-style .arrow {
  margin-left: 5px;
}

.dropdown-element-style {
  font-size: x-large !important;
}

.display-linebreak {
  white-space: pre-line;
}

@media (max-width: 991.98px) {
  .alignElementsCenterAndEnd {
    justify-content: center;
  }
}

@media (min-width: 992px) {
  .alignElementsCenterAndEnd {
    justify-content: space-between;
  }
}

.rowStyling {
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .rowStyling {
    justify-content: space-around;
  }
}

@media (min-width: 1200px) and (max-width: 1640px) {
  .daysOfTheWeekSizing {
    min-width: 14em;
  }
}

@media (min-width: 1286px) and (max-width: 1450px) {
  .rowStyling {
    justify-content: space-around;
    column-gap: 2.25em;
    padding-right: 2em;
  }
}

@media (min-width: 1200px) and (max-width: 1286px) {
  .rowStyling {
    justify-content: flex-start;
    column-gap: 2.25em;
    margin-left: -1em;
    padding-right: 0.5em;
  }
}

@media (min-width: 1450px) and (max-width: 1640px) {
  .rowStyling {
    justify-content: space-around;
    column-gap: 2.25em;
  }
}

html {
  font-size: 1rem;
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 1.2rem;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1.4rem;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 1.6rem;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 63px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.form-control:disabled {
  cursor: auto;
}

.cookiebar {
  max-width: none;
}

.cookiebar .cookiebar-btn:not(.cookiebar-confirm) {
  margin: 0px 5px;
  padding: 10px;
  min-width: 102px;
}

.cookiebar p {
  width: 100%;
}

@media (min-width: 767.99px) {
  .cookiebar .cookiebar-buttons {
    width: 25%;
    place-self: center;
  }
}

@media (max-width: 767.98px) {
  .cookiebar-buttons {
    text-align: center !important;
    width: 100%;
  }
}

.accettaButton {
  background-color: #17ad5a !important;
  margin-top: 5px !important;
}

.hideCookieAlert {
  display: none !important;
}

.cookiebar a {
  color: white;
}

.termsAndConditionsModal .close {
  display: none;
}

.termsAndConditionsModal .modal-title {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.termsAndConditionsModal .modal-header {
  justify-content: center;
}

.termsAndConditionsModal .modal-header h2 {
  text-align: -webkit-center;
  color: #0053a6;
}

.termsAndConditionsModal .buttonPrimary {
  width: auto;
}

.termsAndConditionsModal .icon {
  width: 10em;
  height: 10em;
  align-self: center;
}

.termsAndConditionsToggle {
  display: flex;
  color: #0053a6;
  display: flex;
  justify-content: space-between;
}

.termsAndConditionsToggle a {
  text-decoration: underline !important;
  font-weight: 700;
}

.terminiECondizioniModalFooter {
  justify-content: space-between;
}

.errorModalFooter {
  justify-content: center;
}

.modalBody {
  justify-content: center;
}

.errorMessage {
  text-align: center;
  color: #0053a6;
}

.termsAndConditionsModal .esciButton {
  background-color: red !important;
  min-width: 99px;
}

.termsAndConditionsModal .esciButton:hover {
  background-color: #b52d10 !important;
  min-width: 99px;
}

.disabledButton {
  cursor: not-allowed;
}

.termsAndConditionsModal {
  max-width: 800px !important;
}

.termsAndConditionsModal .modal-content {
  border-radius: 10px;
}

.loadingSpinner {
  z-index: 9999 !important;
}

.breadcrumbTopMargin .breadcrumb {
  margin: 0px !important;
}

.activeBreadCrumb {
  text-decoration: underline;
}
